import { getCurrentInstance, onMounted, ref } from "vue";
import { createGlobalState } from '@vueuse/core'

export const useGlobalState = createGlobalState(() => {
  const showSiteHeader = ref(true);
  const siteHeaderHeight = ref(0);
  const mountedComponents = [];

  function onFirstMounted(callback) {
    const instance = getCurrentInstance();
    const component = instance.type.name || instance.type.__name;
    if (!mountedComponents.includes(component)) {
      onMounted(callback);
      mountedComponents.push(component);
    }
  }

  return { showSiteHeader, siteHeaderHeight, onFirstMounted };
});